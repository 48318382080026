!(function (t, e) {
  function n(t) {
    k.put.call(this, t);
  }
  function a(t) {
    (this.parent = t),
      (this.byStart = [{ start: -1, end: -1 }]),
      (this.byEnd = [{ start: -1, end: -1 }]),
      (this.animating = []),
      (this.startIndex = 0),
      (this.endIndex = 0),
      (this.previousUpdateTime = -1),
      (this.count = 1);
  }
  function i(t, e, n) {
    return t[e] && t[e] === n;
  }
  function r(t, e) {
    var n = {};
    for (var a in t) l.call(e, a) && l.call(t, a) && (n[a] = t[a]);
    return n;
  }
  function s(t, e) {
    return function () {
      if (_.plugin.debug) return t.apply(this, arguments);
      try {
        return t.apply(this, arguments);
      } catch (n) {
        _.plugin.errors.push({ plugin: e, thrown: n, source: t.toString() }), this.emit('pluginerror', _.plugin.errors);
      }
    };
  }
  if (e.addEventListener) {
    var d = Array.prototype,
      o = Object.prototype,
      u = d.forEach,
      c = d.slice,
      l = o.hasOwnProperty,
      p = o.toString,
      f = t.Popcorn,
      h = [],
      v = !1,
      m = !1,
      g = { events: { hash: {}, apis: {} } },
      y = (function () {
        return (
          t.requestAnimationFrame ||
          t.webkitRequestAnimationFrame ||
          t.mozRequestAnimationFrame ||
          t.oRequestAnimationFrame ||
          t.msRequestAnimationFrame ||
          function (e) {
            t.setTimeout(e, 16);
          }
        );
      })(),
      E = function (t) {
        return Object.keys
          ? Object.keys(t)
          : (function (t) {
              var e,
                n = [];
              for (e in t) l.call(t, e) && n.push(e);
              return n;
            })(t);
      },
      k = {
        put: function (t) {
          for (var e in t) t.hasOwnProperty(e) && (this[e] = t[e]);
        },
      },
      _ = function (t, e) {
        return new _.p.init(t, e || null);
      };
    (_.version = '@VERSION'),
      (_.isSupported = !0),
      (_.instances = []),
      (_.p = _.prototype =
        {
          init: function (t, n) {
            var i,
              r,
              s = this;
            {
              if ('function' != typeof t) {
                if ('string' == typeof t)
                  try {
                    i = e.querySelector(t);
                  } catch (d) {
                    throw new Error('Popcorn.js Error: Invalid media element selector: ' + t);
                  }
                if (
                  ((this.media = i || t),
                  (r = (this.media.nodeName && this.media.nodeName.toLowerCase()) || 'video'),
                  (this[r] = this.media),
                  (this.options = _.extend({}, n) || {}),
                  (this.id = this.options.id || _.guid(r)),
                  _.byId(this.id))
                )
                  throw new Error('Popcorn.js Error: Cannot use duplicate ID (' + this.id + ')');
                (this.isDestroyed = !1),
                  (this.data = {
                    running: { cue: [] },
                    timeUpdate: _.nop,
                    disabled: {},
                    events: {},
                    hooks: {},
                    history: [],
                    state: { volume: this.media.volume },
                    trackRefs: {},
                    trackEvents: new a(this),
                  }),
                  _.instances.push(this);
                var o = function () {
                  s.media.currentTime < 0 && (s.media.currentTime = 0),
                    s.media.removeEventListener('loadedmetadata', o, !1);
                  var t, e, n, a, i, r;
                  (t = s.media.duration),
                    (e = t != t ? Number.MAX_VALUE : t + 1),
                    _.addTrackEvent(s, { start: e, end: e }),
                    s.isDestroyed ||
                      ((s.data.durationChange = function () {
                        var t = s.media.duration,
                          e = t + 1,
                          n = s.data.trackEvents.byStart,
                          a = s.data.trackEvents.byEnd;
                        n.pop(), a.pop();
                        for (var i = a.length - 1; i > 0; i--) a[i].end > t && s.removeTrackEvent(a[i]._id);
                        for (var r = 0; r < n.length; r++) n[r].end > t && s.removeTrackEvent(n[r]._id);
                        s.data.trackEvents.byEnd.push({ start: e, end: e }),
                          s.data.trackEvents.byStart.push({ start: e, end: e });
                      }),
                      s.media.addEventListener('durationchange', s.data.durationChange, !1)),
                    s.options.frameAnimation
                      ? ((s.data.timeUpdate = function () {
                          _.timeUpdate(s, {}),
                            _.forEach(_.manifest, function (t, e) {
                              if ((n = s.data.running[e])) {
                                i = n.length;
                                for (var d = 0; i > d; d++)
                                  (a = n[d]), (r = a._natives), r && r.frame && r.frame.call(s, {}, a, s.currentTime());
                              }
                            }),
                            s.emit('timeupdate'),
                            !s.isDestroyed && y(s.data.timeUpdate);
                        }),
                        !s.isDestroyed && y(s.data.timeUpdate))
                      : ((s.data.timeUpdate = function (t) {
                          _.timeUpdate(s, t);
                        }),
                        s.isDestroyed || s.media.addEventListener('timeupdate', s.data.timeUpdate, !1));
                };
                return (
                  s.media.addEventListener(
                    'error',
                    function () {
                      s.error = s.media.error;
                    },
                    !1
                  ),
                  s.media.readyState >= 1 ? o() : s.media.addEventListener('loadedmetadata', o, !1),
                  this
                );
              }
              if ('complete' === e.readyState) return void t(e, _);
              if ((h.push(t), !v)) {
                v = !0;
                var u = function () {
                  (m = !0), e.removeEventListener('DOMContentLoaded', u, !1);
                  for (var t = 0, n = h.length; n > t; t++) h[t].call(e, _);
                  h = null;
                };
                e.addEventListener('DOMContentLoaded', u, !1);
              }
            }
          },
        }),
      (_.p.init.prototype = _.p),
      (_.byId = function (t) {
        for (var e = _.instances, n = e.length, a = 0; n > a; a++) if (e[a].id === t) return e[a];
        return null;
      }),
      (_.forEach = function (t, e, n) {
        if (!t || !e) return {};
        n = n || this;
        var a, i;
        if (u && t.forEach === u) return t.forEach(e, n);
        if ('[object NodeList]' === p.call(t)) {
          for (a = 0, i = t.length; i > a; a++) e.call(n, t[a], a, t);
          return t;
        }
        for (a in t) l.call(t, a) && e.call(n, t[a], a, t);
        return t;
      }),
      (_.extend = function (t) {
        var e = t,
          n = c.call(arguments, 1);
        return (
          _.forEach(n, function (t) {
            for (var n in t) e[n] = t[n];
          }),
          e
        );
      }),
      _.extend(_, {
        noConflict: function (e) {
          return e && (t.Popcorn = f), _;
        },
        error: function (t) {
          throw new Error(t);
        },
        guid: function (t) {
          return _.guid.counter++, (t ? t : '') + (+new Date() + _.guid.counter);
        },
        sizeOf: function (t) {
          var e = 0;
          for (var n in t) e++;
          return e;
        },
        isArray:
          Array.isArray ||
          function (t) {
            return '[object Array]' === p.call(t);
          },
        nop: function () {},
        position: function (n) {
          if (!n.parentNode) return null;
          var a,
            i,
            r,
            s,
            d,
            o,
            u = n.getBoundingClientRect(),
            c = {},
            l = (n.ownerDocument, e.documentElement),
            p = e.body;
          (a = l.clientTop || p.clientTop || 0),
            (i = l.clientLeft || p.clientLeft || 0),
            (r = (t.pageYOffset && l.scrollTop) || p.scrollTop),
            (s = (t.pageXOffset && l.scrollLeft) || p.scrollLeft),
            (d = Math.ceil(u.top + r - a)),
            (o = Math.ceil(u.left + s - i));
          for (var f in u) c[f] = Math.round(u[f]);
          return _.extend({}, c, { top: d, left: o });
        },
        disable: function (t, e) {
          if (!t.data.disabled[e]) {
            if (((t.data.disabled[e] = !0), e in _.registryByName && t.data.running[e]))
              for (var n, a = t.data.running[e].length - 1; a >= 0; a--)
                (n = t.data.running[e][a]),
                  n._natives.end.call(t, null, n),
                  t.emit('trackend', _.extend({}, n, { plugin: n.type, type: 'trackend' }));
            return t;
          }
        },
        enable: function (t, e) {
          if (t.data.disabled[e]) {
            if (((t.data.disabled[e] = !1), e in _.registryByName && t.data.running[e]))
              for (var n, a = t.data.running[e].length - 1; a >= 0; a--)
                (n = t.data.running[e][a]),
                  n._natives.start.call(t, null, n),
                  t.emit('trackstart', _.extend({}, n, { plugin: n.type, type: 'trackstart', track: n }));
            return t;
          }
        },
        destroy: function (t) {
          var e,
            n,
            a,
            i,
            r = t.data.events,
            s = t.data.trackEvents;
          for (n in r) {
            e = r[n];
            for (a in e) delete e[a];
            r[n] = null;
          }
          for (i in _.registryByName) _.removePlugin(t, i);
          (s.byStart.length = 0),
            (s.byEnd.length = 0),
            t.isDestroyed ||
              (t.data.timeUpdate && t.media.removeEventListener('timeupdate', t.data.timeUpdate, !1),
              (t.isDestroyed = !0)),
            _.instances.splice(_.instances.indexOf(t), 1);
        },
      }),
      (_.guid.counter = 1),
      _.extend(
        _.p,
        (function () {
          var t =
              'load play pause currentTime playbackRate volume duration preload playbackRate autoplay loop controls muted buffered readyState seeking paused played seekable ended',
            e = {};
          return (
            _.forEach(t.split(/\s+/g), function (t) {
              e[t] = function (e) {
                var n;
                return 'function' == typeof this.media[t]
                  ? (null != e && /play|pause/.test(t) && (this.media.currentTime = _.util.toSeconds(e)),
                    this.media[t](),
                    this)
                  : null != e
                  ? ((n = this.media[t]),
                    (this.media[t] = e),
                    n !== e && this.emit('attrchange', { attribute: t, previousValue: n, currentValue: e }),
                    this)
                  : this.media[t];
              };
            }),
            e
          );
        })()
      ),
      _.forEach('enable disable'.split(' '), function (t) {
        _.p[t] = function (e) {
          return _[t](this, e);
        };
      }),
      _.extend(_.p, {
        roundTime: function () {
          return Math.round(this.media.currentTime);
        },
        exec: function (t, e, a) {
          var i,
            r,
            s,
            d = arguments.length,
            o = 'trackadded';
          try {
            r = _.util.toSeconds(t);
          } catch (u) {}
          if (('number' == typeof r && (t = r), 'number' == typeof t && 2 === d)) (a = e), (e = t), (t = _.guid('cue'));
          else if (1 === d) e = -1;
          else if ((i = this.getTrackEvent(t)))
            this.data.trackEvents.remove(t),
              n.end(this, i),
              _.removeTrackEvent.ref(this, t),
              (o = 'cuechange'),
              'string' == typeof t &&
                2 === d &&
                ('number' == typeof e && (a = i._natives.start), 'function' == typeof e && ((a = e), (e = i.start)));
          else if (d >= 2) {
            if ('string' == typeof e) {
              try {
                r = _.util.toSeconds(e);
              } catch (u) {}
              e = r;
            }
            'number' == typeof e && (a = a || _.nop()), 'function' == typeof e && ((a = e), (e = -1));
          }
          return (
            (s = {
              id: t,
              start: e,
              end: e + 1,
              _running: !1,
              _natives: { start: a || _.nop, end: _.nop, type: 'cue' },
            }),
            i && (s = _.extend(i, s)),
            'cuechange' === o
              ? ((s._id = s.id || s._id || _.guid(s._natives.type)),
                this.data.trackEvents.add(s),
                n.start(this, s),
                this.timeUpdate(this, null, !0),
                _.addTrackEvent.ref(this, s),
                this.emit(
                  o,
                  _.extend({}, s, {
                    id: t,
                    type: o,
                    previousValue: { time: i.start, fn: i._natives.start },
                    currentValue: { time: e, fn: a || _.nop },
                    track: i,
                  })
                ))
              : _.addTrackEvent(this, s),
            this
          );
        },
        mute: function (t) {
          var e = null == t || t === !0 ? 'muted' : 'unmuted';
          return (
            'unmuted' === e && ((this.media.muted = !1), (this.media.volume = this.data.state.volume)),
            'muted' === e && ((this.data.state.volume = this.media.volume), (this.media.muted = !0)),
            this.emit(e),
            this
          );
        },
        unmute: function (t) {
          return this.mute(null == t ? !1 : !t);
        },
        position: function () {
          return _.position(this.media);
        },
        toggle: function (t) {
          return _[this.data.disabled[t] ? 'enable' : 'disable'](this, t);
        },
        defaults: function (t, e) {
          return _.isArray(t)
            ? (_.forEach(
                t,
                function (t) {
                  for (var e in t) this.defaults(e, t[e]);
                },
                this
              ),
              this)
            : (this.options.defaults || (this.options.defaults = {}),
              this.options.defaults[t] || (this.options.defaults[t] = {}),
              _.extend(this.options.defaults[t], e),
              this);
        },
      }),
      (_.Events = {
        UIEvents: 'blur focus focusin focusout load resize scroll unload',
        MouseEvents: 'mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave click dblclick',
        Events:
          'loadstart progress suspend emptied stalled play pause error loadedmetadata loadeddata waiting playing canplay canplaythrough seeking seeked timeupdate ended ratechange durationchange volumechange',
      }),
      (_.Events.Natives = _.Events.UIEvents + ' ' + _.Events.MouseEvents + ' ' + _.Events.Events),
      (g.events.apiTypes = ['UIEvents', 'MouseEvents', 'Events']),
      (function (t, e) {
        for (var n = g.events.apiTypes, a = t.Natives.split(/\s+/g), i = 0, r = a.length; r > i; i++) e.hash[a[i]] = !0;
        n.forEach(function (n) {
          e.apis[n] = {};
          for (var a = t[n].split(/\s+/g), i = a.length, r = 0; i > r; r++) e.apis[n][a[r]] = !0;
        });
      })(_.Events, g.events),
      (_.events = {
        isNative: function (t) {
          return !!g.events.hash[t];
        },
        getInterface: function (t) {
          if (!_.events.isNative(t)) return !1;
          for (var e, n, a = g.events, i = a.apiTypes, r = a.apis, s = 0, d = i.length; d > s; s++)
            if (((n = i[s]), r[n][t])) {
              e = n;
              break;
            }
          return e;
        },
        all: _.Events.Natives.split(/\s+/g),
        fn: {
          trigger: function (n, a) {
            var i,
              r,
              s,
              d = this.data.events[n];
            if (d) {
              if ((i = _.events.getInterface(n)))
                return (r = e.createEvent(i)), r.initEvent(n, !0, !0, t, 1), this.media.dispatchEvent(r), this;
              for (s = d.slice(); s.length; ) s.shift().call(this, a);
            }
            return this;
          },
          listen: function (t, e) {
            var n,
              a,
              i = this,
              r = !0,
              s = _.events.hooks[t];
            if ('function' != typeof e) throw new Error('Popcorn.js Error: Listener is not a function');
            return (
              this.data.events[t] || ((this.data.events[t] = []), (r = !1)),
              s &&
                (s.add && s.add.call(this, {}, e),
                s.bind && (t = s.bind),
                s.handler &&
                  ((a = e),
                  (e = function (t) {
                    s.handler.call(i, t, a);
                  })),
                (r = !0),
                this.data.events[t] || ((this.data.events[t] = []), (r = !1))),
              this.data.events[t].push(e),
              !r &&
                _.events.all.indexOf(t) > -1 &&
                this.media.addEventListener(
                  t,
                  function (e) {
                    if (i.data.events[t]) for (n = i.data.events[t].slice(); n.length; ) n.shift().call(i, e);
                  },
                  !1
                ),
              this
            );
          },
          unlisten: function (t, e) {
            var n,
              a = this.data.events[t];
            if (a) {
              if ('string' == typeof e) {
                for (var i = 0; i < a.length; i++) a[i].name === e && a.splice(i--, 1);
                return this;
              }
              if ('function' == typeof e) {
                for (; -1 !== n; ) (n = a.indexOf(e)), -1 !== n && a.splice(n, 1);
                return this;
              }
              return (this.data.events[t] = null), this;
            }
          },
        },
        hooks: {
          canplayall: {
            bind: 'canplaythrough',
            add: function (t, e) {
              var n = !1;
              this.media.readyState &&
                (setTimeout(
                  function () {
                    e.call(this, t);
                  }.bind(this),
                  0
                ),
                (n = !0)),
                (this.data.hooks.canplayall = { fired: n });
            },
            handler: function (t, e) {
              this.data.hooks.canplayall.fired || (e.call(this, t), (this.data.hooks.canplayall.fired = !0));
            },
          },
        },
      }),
      _.forEach(
        [
          ['trigger', 'emit'],
          ['listen', 'on'],
          ['unlisten', 'off'],
        ],
        function (t) {
          _.p[t[0]] = _.p[t[1]] = _.events.fn[t[0]];
        }
      ),
      (n.start = function (t, e) {
        e.end > t.media.currentTime &&
          e.start <= t.media.currentTime &&
          !e._running &&
          ((e._running = !0),
          t.data.running[e._natives.type].push(e),
          t.data.disabled[e._natives.type] ||
            (e._natives.start.call(t, null, e),
            t.emit('trackstart', _.extend({}, e, { plugin: e._natives.type, type: 'trackstart', track: e }))));
      }),
      (n.end = function (t, e) {
        var n;
        (e.end <= t.media.currentTime || e.start > t.media.currentTime) &&
          e._running &&
          ((n = t.data.running[e._natives.type]),
          (e._running = !1),
          n.splice(n.indexOf(e), 1),
          t.data.disabled[e._natives.type] ||
            (e._natives.end.call(t, null, e),
            t.emit('trackend', _.extend({}, e, { plugin: e._natives.type, type: 'trackend', track: e }))));
      }),
      (a.prototype.where = function (t) {
        return (this.parent.getTrackEvents() || []).filter(function (e) {
          var n, a;
          if (!t) return !0;
          for (n in t) if (((a = t[n]), i(e, n, a) || i(e._natives, n, a))) return !0;
          return !1;
        });
      }),
      (a.prototype.add = function (t) {
        var e,
          n,
          a = this.byStart,
          i = this.byEnd;
        for (
          t && t._id && this.parent.data.history.push(t._id),
            t.start = _.util.toSeconds(t.start, this.parent.options.framerate),
            t.end = _.util.toSeconds(t.end, this.parent.options.framerate),
            e = a.length - 1;
          e >= 0;
          e--
        )
          if (t.start >= a[e].start) {
            a.splice(e + 1, 0, t);
            break;
          }
        for (n = i.length - 1; n >= 0; n--)
          if (t.end > i[n].end) {
            i.splice(n + 1, 0, t);
            break;
          }
        e <= this.parent.data.trackEvents.startIndex &&
          t.start <= this.parent.data.trackEvents.previousUpdateTime &&
          this.parent.data.trackEvents.startIndex++,
          n <= this.parent.data.trackEvents.endIndex &&
            t.end < this.parent.data.trackEvents.previousUpdateTime &&
            this.parent.data.trackEvents.endIndex++,
          this.count++;
      }),
      (a.prototype.remove = function (t, e) {
        if ((t instanceof n && (t = t.id), 'object' == typeof t))
          return (
            this.where(t).forEach(function (t) {
              this.removeTrackEvent(t._id);
            }, this.parent),
            this
          );
        var a,
          i,
          r,
          s,
          d,
          o = this.byStart.length,
          u = 0,
          c = 0,
          l = [],
          p = [],
          f = [],
          h = [];
        for (e = e || {}; --o > -1; )
          (a = this.byStart[u]),
            (i = this.byEnd[u]),
            a._id || (l.push(a), p.push(i)),
            a._id && (a._id !== t && l.push(a), i._id !== t && p.push(i), a._id === t && ((c = u), (d = a))),
            u++;
        if (((o = this.animating.length), (u = 0), o))
          for (; --o > -1; ) (r = this.animating[u]), r._id || f.push(r), r._id && r._id !== t && f.push(r), u++;
        c <= this.startIndex && this.startIndex--,
          c <= this.endIndex && this.endIndex--,
          (this.byStart = l),
          (this.byEnd = p),
          (this.animating = f),
          this.count--,
          (s = this.parent.data.history.length);
        for (var v = 0; s > v; v++) this.parent.data.history[v] !== t && h.push(this.parent.data.history[v]);
        this.parent.data.history = h;
      }),
      (_.addTrackEvent = function (t, e) {
        var a;
        e instanceof n ||
          ((e = new n(e)),
          e &&
            e._natives &&
            e._natives.type &&
            t.options.defaults &&
            t.options.defaults[e._natives.type] &&
            ((a = _.extend({}, e)), _.extend(e, t.options.defaults[e._natives.type], a)),
          e._natives &&
            ((e._id = e.id || e._id || _.guid(e._natives.type)),
            e._natives._setup &&
              (e._natives._setup.call(t, e),
              t.emit('tracksetup', _.extend({}, e, { plugin: e._natives.type, type: 'tracksetup', track: e })))),
          t.data.trackEvents.add(e),
          n.start(t, e),
          this.timeUpdate(t, null, !0),
          e._id && _.addTrackEvent.ref(t, e),
          t.emit(
            'trackadded',
            _.extend({}, e, e._natives ? { plugin: e._natives.type } : {}, { type: 'trackadded', track: e })
          ));
      }),
      (_.addTrackEvent.ref = function (t, e) {
        return (t.data.trackRefs[e._id] = e), t;
      }),
      (_.removeTrackEvent = function (t, e) {
        var n = t.getTrackEvent(e);
        n &&
          (n._natives._teardown && n._natives._teardown.call(t, n),
          t.data.trackEvents.remove(e),
          _.removeTrackEvent.ref(t, e),
          n._natives &&
            t.emit('trackremoved', _.extend({}, n, { plugin: n._natives.type, type: 'trackremoved', track: n })));
      }),
      (_.removeTrackEvent.ref = function (t, e) {
        return delete t.data.trackRefs[e], t;
      }),
      (_.getTrackEvents = function (t) {
        for (var e, n = [], a = t.data.trackEvents.byStart, i = a.length, r = 0; i > r; r++)
          (e = a[r]), e._id && n.push(e);
        return n;
      }),
      (_.getTrackEvents.ref = function (t) {
        return t.data.trackRefs;
      }),
      (_.getTrackEvent = function (t, e) {
        return t.data.trackRefs[e];
      }),
      (_.getTrackEvent.ref = function (t, e) {
        return t.data.trackRefs[e];
      }),
      (_.getLastTrackEventId = function (t) {
        return t.data.history[t.data.history.length - 1];
      }),
      (_.timeUpdate = function (t, e) {
        var n,
          a,
          i,
          r,
          s,
          d = t.media.currentTime,
          o = t.data.trackEvents.previousUpdateTime,
          u = t.data.trackEvents,
          c = u.endIndex,
          l = u.startIndex,
          p = u.byStart.length,
          f = u.byEnd.length,
          h = _.registryByName,
          v = 'trackstart',
          m = 'trackend';
        if (d >= o) {
          for (; u.byEnd[c] && u.byEnd[c].end <= d; ) {
            if (((n = u.byEnd[c]), (i = n._natives), (r = i && i.type), i && !h[r] && !t[r]))
              return void _.removeTrackEvent(t, n._id);
            n._running === !0 &&
              ((n._running = !1),
              (s = t.data.running[r]),
              s.splice(s.indexOf(n), 1),
              t.data.disabled[r] ||
                (i.end.call(t, e, n), t.emit(m, _.extend({}, n, { plugin: r, type: m, track: n })))),
              c++;
          }
          for (; u.byStart[l] && u.byStart[l].start <= d; ) {
            if (((a = u.byStart[l]), (i = a._natives), (r = i && i.type), i && !h[r] && !t[r]))
              return void _.removeTrackEvent(t, a._id);
            a.end > d &&
              a._running === !1 &&
              ((a._running = !0),
              t.data.running[r].push(a),
              t.data.disabled[r] ||
                (i.start.call(t, e, a), t.emit(v, _.extend({}, a, { plugin: r, type: v, track: a })))),
              l++;
          }
        } else if (o > d) {
          for (; u.byStart[l] && u.byStart[l].start > d; ) {
            if (((a = u.byStart[l]), (i = a._natives), (r = i && i.type), i && !h[r] && !t[r]))
              return void _.removeTrackEvent(t, a._id);
            a._running === !0 &&
              ((a._running = !1),
              (s = t.data.running[r]),
              s.splice(s.indexOf(a), 1),
              t.data.disabled[r] ||
                (i.end.call(t, e, a), t.emit(m, _.extend({}, a, { plugin: r, type: m, track: a })))),
              l--;
          }
          for (; u.byEnd[c] && u.byEnd[c].end > d; ) {
            if (((n = u.byEnd[c]), (i = n._natives), (r = i && i.type), i && !h[r] && !t[r]))
              return void _.removeTrackEvent(t, n._id);
            n.start <= d &&
              n._running === !1 &&
              ((n._running = !0),
              t.data.running[r].push(n),
              t.data.disabled[r] ||
                (i.start.call(t, e, n), t.emit(v, _.extend({}, n, { plugin: r, type: v, track: n })))),
              c--;
          }
        }
        (u.endIndex = c),
          (u.startIndex = l),
          (u.previousUpdateTime = d),
          u.byStart.length < p && u.startIndex--,
          u.byEnd.length < f && u.endIndex--;
      }),
      _.extend(_.p, {
        getTrackEvents: function () {
          return _.getTrackEvents.call(null, this);
        },
        getTrackEvent: function (t) {
          return _.getTrackEvent.call(null, this, t);
        },
        getLastTrackEventId: function () {
          return _.getLastTrackEventId.call(null, this);
        },
        removeTrackEvent: function (t) {
          return _.removeTrackEvent.call(null, this, t), this;
        },
        removePlugin: function (t) {
          return _.removePlugin.call(null, this, t), this;
        },
        timeUpdate: function (t) {
          return _.timeUpdate.call(null, this, t), this;
        },
        destroy: function () {
          return _.destroy.call(null, this), this;
        },
      }),
      (_.manifest = {}),
      (_.registry = []),
      (_.registryByName = {}),
      (_.plugin = function (t, e, a) {
        if (_.protect.natives.indexOf(t.toLowerCase()) >= 0)
          return void _.error("'" + t + "' is a protected function name");
        var i = 'function' == typeof e,
          d = ['start', 'end', 'type', 'manifest'],
          o = ['_setup', '_teardown', 'start', 'end', 'frame'],
          u = {},
          p = function (t, e) {
            return (
              (t = t || _.nop),
              (e = e || _.nop),
              function () {
                t.apply(this, arguments), e.apply(this, arguments);
              }
            );
          };
        (_.manifest[t] = a = a || e.manifest || {}),
          o.forEach(function (n) {
            e[n] = s(e[n] || _.nop, t);
          });
        var f = function (e, i) {
          if (!i) return this;
          if (i.ranges && _.isArray(i.ranges))
            return (
              _.forEach(
                i.ranges,
                function (e) {
                  var n = _.extend({}, i, e);
                  delete n.ranges, this[t](n);
                },
                this
              ),
              this
            );
          var r,
            s = (i._natives = {}),
            u = '';
          return (
            _.extend(s, e),
            (i._natives.type = i._natives.plugin = t),
            (i._running = !1),
            (s.start = s.start || s['in']),
            (s.end = s.end || s.out),
            i.once &&
              (s.end = p(s.end, function () {
                this.removeTrackEvent(i._id);
              })),
            (s._teardown = p(function () {
              var t = c.call(arguments),
                e = this.data.running[s.type];
              t.unshift(null),
                t[1]._running && e.splice(e.indexOf(i), 1) && s.end.apply(this, t),
                (t[1]._running = !1),
                this.emit(
                  'trackend',
                  _.extend({}, i, { plugin: s.type, type: 'trackend', track: _.getTrackEvent(this, i.id || i._id) })
                );
            }, s._teardown)),
            (s._teardown = p(s._teardown, function () {
              this.emit(
                'trackteardown',
                _.extend({}, i, { plugin: t, type: 'trackteardown', track: _.getTrackEvent(this, i.id || i._id) })
              );
            })),
            (i.compose = i.compose || []),
            'string' == typeof i.compose && (i.compose = i.compose.split(' ')),
            (i.effect = i.effect || []),
            'string' == typeof i.effect && (i.effect = i.effect.split(' ')),
            (i.compose = i.compose.concat(i.effect)),
            i.compose.forEach(function (t) {
              (u = _.compositions[t] || {}),
                o.forEach(function (t) {
                  s[t] = p(s[t], u[t]);
                });
            }),
            (i._natives.manifest = a),
            'start' in i || (i.start = i['in'] || 0),
            i.end || 0 === i.end || (i.end = i.out || Number.MAX_VALUE),
            l.call(i, 'toString') ||
              (i.toString = function () {
                var e = ['start: ' + i.start, 'end: ' + i.end, 'id: ' + (i.id || i._id)];
                return null != i.target && e.push('target: ' + i.target), t + ' ( ' + e.join(', ') + ' )';
              }),
            i.target || ((r = 'options' in a && a.options), (i.target = r && 'target' in r && r.target)),
            !i._id && i._natives && (i._id = _.guid(i._natives.type)),
            i instanceof n
              ? (i._natives &&
                  ((i._id = i.id || i._id || _.guid(i._natives.type)),
                  i._natives._setup &&
                    (i._natives._setup.call(this, i),
                    this.emit(
                      'tracksetup',
                      _.extend({}, i, { plugin: i._natives.type, type: 'tracksetup', track: i })
                    ))),
                this.data.trackEvents.add(i),
                n.start(this, i),
                this.timeUpdate(this, null, !0),
                i._id && _.addTrackEvent.ref(this, i))
              : _.addTrackEvent(this, i),
            _.forEach(
              e,
              function (t, e) {
                -1 === d.indexOf(e) && this.on(e, t);
              },
              this
            ),
            this
          );
        };
        (_.p[t] = u[t] =
          function (a, s) {
            {
              var d, o, u, c, p;
              arguments.length;
            }
            if (a && !s) (s = a), (a = null);
            else {
              if ((d = this.getTrackEvent(a)))
                return (
                  (p = s),
                  (c = r(d, p)),
                  d._natives._update
                    ? (this.data.trackEvents.remove(d),
                      l.call(s, 'start') && (d.start = s.start),
                      l.call(s, 'end') && (d.end = s.end),
                      n.end(this, d),
                      i && e.call(this, d),
                      d._natives._update.call(this, d, s),
                      this.data.trackEvents.add(d),
                      n.start(this, d),
                      'cue' !== d._natives.type &&
                        this.emit('trackchange', {
                          id: d.id,
                          type: 'trackchange',
                          previousValue: c,
                          currentValue: p,
                          track: d,
                        }),
                      this)
                    : (_.extend(d, s),
                      this.data.trackEvents.remove(a),
                      d._natives._teardown && d._natives._teardown.call(this, d),
                      _.removeTrackEvent.ref(this, a),
                      i
                        ? f.call(this, e.call(this, d), d)
                        : ((d._id = d.id || d._id || _.guid(d._natives.type)),
                          d._natives &&
                            d._natives._setup &&
                            (d._natives._setup.call(this, d),
                            this.emit(
                              'tracksetup',
                              _.extend({}, d, { plugin: d._natives.type, type: 'tracksetup', track: d })
                            )),
                          this.data.trackEvents.add(d),
                          n.start(this, d),
                          this.timeUpdate(this, null, !0),
                          _.addTrackEvent.ref(this, d)),
                      this.emit('trackchange', {
                        id: d.id,
                        type: 'trackchange',
                        previousValue: c,
                        currentValue: d,
                        track: d,
                      }),
                      this)
                );
              s.id = a;
            }
            return (
              (this.data.running[t] = this.data.running[t] || []),
              (o = (this.options.defaults && this.options.defaults[t]) || {}),
              (u = _.extend({}, o, s)),
              f.call(this, i ? e.call(this, u) : e, u),
              this
            );
          }),
          a && _.extend(e, { manifest: a });
        var h = { fn: u[t], definition: e, base: e, parents: [], name: t };
        return _.registry.push(_.extend(u, h, { type: t })), (_.registryByName[t] = h), u;
      }),
      (_.plugin.errors = []),
      (_.plugin.debug = '@VERSION' === _.version),
      (_.removePlugin = function (t, e) {
        if (!e) {
          if (((e = t), (t = _.p), _.protect.natives.indexOf(e.toLowerCase()) >= 0))
            return void _.error("'" + e + "' is a protected function name");
          var n,
            a = _.registry.length;
          for (n = 0; a > n; n++)
            if (_.registry[n].name === e)
              return _.registry.splice(n, 1), delete _.registryByName[e], delete _.manifest[e], void delete t[e];
        }
        var i,
          r,
          s = t.data.trackEvents.byStart,
          d = t.data.trackEvents.byEnd,
          o = t.data.trackEvents.animating;
        for (i = 0, r = s.length; r > i; i++)
          s[i] &&
            s[i]._natives &&
            s[i]._natives.type === e &&
            (s[i]._natives._teardown && s[i]._natives._teardown.call(t, s[i]),
            s.splice(i, 1),
            i--,
            r--,
            t.data.trackEvents.startIndex <= i && (t.data.trackEvents.startIndex--, t.data.trackEvents.endIndex--)),
            d[i] && d[i]._natives && d[i]._natives.type === e && d.splice(i, 1);
        for (i = 0, r = o.length; r > i; i++)
          o[i] && o[i]._natives && o[i]._natives.type === e && (o.splice(i, 1), i--, r--);
      }),
      (_.compositions = {}),
      (_.compose = function (t, e, n) {
        (_.manifest[t] = n = n || e.manifest || {}), (_.compositions[t] = e);
      }),
      (_.plugin.effect = _.effect = _.compose);
    var x = /^(?:\.|#|\[)/;
    _.dom = {
      debug: !1,
      find: function (t, n) {
        var a = null;
        if (((n = n || e), t)) {
          if (!x.test(t) && ((a = e.getElementById(t)), null !== a)) return a;
          try {
            a = n.querySelector(t);
          } catch (i) {
            if (_.dom.debug) throw new Error(i);
          }
        }
        return a;
      },
    };
    var b = /\?/,
      T = {
        ajax: null,
        url: '',
        data: '',
        dataType: '',
        success: _.nop,
        type: 'GET',
        async: !0,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      };
    (_.xhr = function (t) {
      var e;
      return (
        (t.dataType = (t.dataType && t.dataType.toLowerCase()) || null),
        !t.dataType || ('jsonp' !== t.dataType && 'script' !== t.dataType)
          ? ((e = _.extend({}, T, t)),
            (e.ajax = new XMLHttpRequest()),
            e.ajax
              ? ('GET' === e.type && e.data && ((e.url += (b.test(e.url) ? '&' : '?') + e.data), (e.data = null)),
                e.ajax.open(e.type, e.url, e.async),
                'POST' === e.type && e.ajax.setRequestHeader('Content-Type', e.contentType),
                e.ajax.send(e.data || null),
                _.xhr.httpData(e))
              : void 0)
          : void _.xhr.getJSONP(t.url, t.success, 'script' === t.dataType)
      );
    }),
      (_.xhr.httpData = function (t) {
        var e,
          n,
          a = null,
          i = null;
        return (
          (t.ajax.onreadystatechange = function () {
            if (4 === t.ajax.readyState) {
              try {
                a = JSON.parse(t.ajax.responseText);
              } catch (r) {}
              if (
                ((e = { xml: t.ajax.responseXML, text: t.ajax.responseText, json: a }),
                !e.xml || !e.xml.documentElement)
              ) {
                e.xml = null;
                try {
                  (n = new DOMParser()),
                    (i = n.parseFromString(t.ajax.responseText, 'text/xml')),
                    i.getElementsByTagName('parsererror').length || (e.xml = i);
                } catch (r) {}
              }
              t.dataType && (e = e[t.dataType]), t.success.call(t.ajax, e);
            }
          }),
          e
        );
      }),
      (_.xhr.getJSONP = function (t, n, a) {
        var i,
          r,
          s,
          d = e.head || e.getElementsByTagName('head')[0] || e.documentElement,
          o = e.createElement('script'),
          u = !1,
          c = [],
          l = /(=)\?(?=&|$)|\?\?/;
        a ||
          ((s = t.match(/(callback=[^&]*)/)),
          null !== s && s.length
            ? ((i = s[1].split('=')[1]),
              '?' === i && (i = 'jsonp'),
              (r = _.guid(i)),
              (t = t.replace(/(callback=[^&]*)/, 'callback=' + r)))
            : ((r = _.guid('jsonp')),
              l.test(t) && (t = t.replace(l, '$1' + r)),
              (c = t.split(/\?(.+)?/)),
              (t = c[0] + '?'),
              c[1] && (t += c[1] + '&'),
              (t += 'callback=' + r)),
          (window[r] = function (t) {
            n && n(t), (u = !0);
          })),
          o.addEventListener(
            'load',
            function () {
              a && n && n(), u && delete window[r], d.removeChild(o);
            },
            !1
          ),
          o.addEventListener(
            'error',
            function (t) {
              n && n({ error: t }), a || delete window[r], d.removeChild(o);
            },
            !1
          ),
          (o.src = t),
          d.insertBefore(o, d.firstChild);
      }),
      (_.getJSONP = _.xhr.getJSONP),
      (_.getScript = _.xhr.getScript =
        function (t, e) {
          return _.xhr.getJSONP(t, e, !0);
        }),
      (_.util = {
        toSeconds: function (t, e) {
          var n,
            a,
            i,
            r,
            s,
            d,
            o = /^([0-9]+:){0,2}[0-9]+([.;][0-9]+)?$/,
            u = 'Invalid time format';
          return 'number' == typeof t
            ? t
            : ('string' != typeof t || o.test(t) || _.error(u),
              (n = t.split(':')),
              (a = n.length - 1),
              (i = n[a]),
              i.indexOf(';') > -1 &&
                ((s = i.split(';')),
                (d = 0),
                e && 'number' == typeof e && (d = parseFloat(s[1], 10) / e),
                (n[a] = parseInt(s[0], 10) + d)),
              (r = n[0]),
              {
                1: parseFloat(r, 10),
                2: 60 * parseInt(r, 10) + parseFloat(n[1], 10),
                3: 3600 * parseInt(r, 10) + 60 * parseInt(n[1], 10) + parseFloat(n[2], 10),
              }[n.length || 1]);
        },
      }),
      (_.p.cue = _.p.exec),
      (_.protect = {
        natives: E(_.p).map(function (t) {
          return t.toLowerCase();
        }),
      }),
      _.forEach({ listen: 'on', unlisten: 'off', trigger: 'emit', exec: 'cue' }, function (t, e) {
        var n = _.p[e];
        _.p[e] = function () {
          return (
            'undefined' != typeof console &&
              console.warn &&
              (console.warn(
                "Deprecated method '" + e + "', " + (null == t ? 'do not use.' : "use '" + t + "' instead.")
              ),
              (_.p[e] = n)),
            _.p[t].apply(this, [].slice.call(arguments))
          );
        };
      }),
      (t.Popcorn = _);
  } else {
    t.Popcorn = { isSupported: !1 };
    for (
      var w =
        'byId forEach extend effects error guid sizeOf isArray nop position disable enable destroyaddTrackEvent removeTrackEvent getTrackEvents getTrackEvent getLastTrackEventId timeUpdate plugin removePlugin compose effect xhr getJSONP getScript'.split(
          /\s+/
        );
      w.length;

    )
      t.Popcorn[w.shift()] = function () {};
  }
})(window, window.document);
1;
